import React from 'react'
import { wrapper, title, tweetWrapper, tweetColumn, tweetContainer, tweetUpper, nameContainer, tweetText, imgSrc, hashtags, twitter } from './Tweets.module.scss'
import profilePic1 from '../../assets/images/tweetpics/profilePic1.png'
import profilePic2 from '../../assets/images/tweetpics/profilePic2.png'
import profilePic3 from '../../assets/images/tweetpics/profilePic3.png'
import profilePic4 from '../../assets/images/tweetpics/profilePic4.jpg'
import profilePic5 from '../../assets/images/tweetpics/profilePic5.jpg'
import profilePic6 from '../../assets/images/tweetpics/profilePic6.jpg'
import profilePic7 from '../../assets/images/tweetpics/profilePic7.jpg'
import profilePic8 from '../../assets/images/tweetpics/profilePic8.jpg'
import profilePic9 from '../../assets/images/tweetpics/profilePic9.jpeg'
import profilePic10 from '../../assets/images/tweetpics/profilePic10.jpg'
import profilePic11 from '../../assets/images/tweetpics/profilePic11.jpg'
import twitterIcon from '../../assets/images/twitterIcon.svg'

const data = [
  {
    name: 'John Passfield',
    username: 'John Passfield',
    imgSrc: profilePic9,
    text: `If you want to improve your UI skills then
    check out the Designing Interfaces book.
    It has some great UI tips that I’ve already applied to my latest mobile apps.`
  },
  {
    name: 'Mateusz K.',
    username: 'Matroyka',
    imgSrc: profilePic2,
    text: `Mindblowing. The button design chapter
    was the first thing I’ve read. All the tips from the book I’m already using in my web projects. My favourite one is using the ‘W’ letter as a grid setting for a perfect button alignment.  Curious? `
  },
  {
    name: 'Dara Chilton',
    username: '7luckydogs',
    imgSrc: profilePic5,
    text: `Need a good book about designing user interfaces? Check this one out!`
  },
  {
    name: 'Julia Sańczyk',
    username: 'julia_sanczyk',
    imgSrc: profilePic11,
    text: `As a junior designer, I often read other
    design materials and think ‘ok, good to
    know, but HOW to actually do it?’.`,
    text2: `
    Designing Interfaces brings me a lot
    closer to finally knowing HOW to do it
    well with an easy to understand, step
    by step detailed approach!
    #designingui`,
    hashtags: [
      'designingui'
    ]
  },
  {
    name: 'Małgosia Toporowska',
    username: 'm_stefanska11',
    imgSrc: profilePic4,
    text: `Designing Interfaces is just awesome
    resource to learn UI design. The book is pure knowledge, with no filler and no useless content.
    `,
    text2: `Straight to the point and done in a
    beautiful, appealing way. It simply rocks!
    `,
    hashtags: [
      'designingui'
    ]
  },
  {
    name: 'Meta Knight',
    username: 'knightladislav',
    imgSrc: profilePic7,
    text: `A must read for all of UI Designers!
    It helped me grasp quite a few loose concepts and it added to my current knowledge base in a meaningful and
    clear way. Go check it out now! #designingui`
  },
  {
    name: 'Lilianna',
    username: 'lilalostinlondon',
    imgSrc: profilePic1,
    text: `Thanks to this great book I’ve finally
    created my project portfolio, learned
    dozens of helpful tricks and got my first
    job as an UI designer.`
  },
  {
    name: 'Jackie',
    username: 'jakyraptor',
    imgSrc: profilePic10,
    text: `Designing User Interfaces is an great source of UI design knowledge!`
  },
  {
    name: 'Marc Goritschnig',
    username: 'marcgoritschnig',
    imgSrc: profilePic6,
    text: `Whoa! Designing User Interfaces is an awesome source of UI design knowledge! #designingui`
  },
  {
    name: 'Szymon',
    username: 'szymeek_',
    imgSrc: profilePic8,
    text: `Total must have for everyone!
    As a junior designer, who wants to be
    on the top of the design trends, this is the 
    best option. It helps me with my personal
    development in becoming a better
    designer!🔥 #projektowanieui`
  },
  {
    name: 'Malcolm',
    username: 'malc0lm85',
    imgSrc: profilePic3,
    text: `This book is the best comprehensive
    guide to becoming a professional UI
    Designer. Just buy it and thank me later!`
  },

]
const Tweets = () => {
  return (
    <div className={wrapper}>
      <h2 className={title}>What others say about<br />the book?</h2>
      <div className={tweetWrapper}>
        <div className={tweetColumn}>
          <SingleTweet data={data[0]} />
          <SingleTweet data={data[1]} />
          <SingleTweet data={data[2]} />
        </div>
        <div className={tweetColumn}>
          <SingleTweet data={data[3]} />
          <SingleTweet data={data[4]} />
        </div>
        <div className={tweetColumn}>
          <SingleTweet data={data[5]} />
          <SingleTweet data={data[6]} />
          <SingleTweet data={data[7]} />
        </div>
        <div className={tweetColumn}>
          <SingleTweet data={data[8]} />
          <SingleTweet data={data[9]} />
          <SingleTweet data={data[10]} />
        </div>
      </div>
    </div>
  );
}


const SingleTweet = ({ data }) => {
  return (
    <div className={tweetContainer}>
      <div className={tweetUpper}>
        <img src={data.imgSrc} className={imgSrc} alt={`${data.username} profilepic`} />
        {/* <img src={data.imgSrc} alt={`${data.username} profilepic`} /> */}
        <div className={nameContainer}>
          <span>{data.name}</span>
          <span>@{data.username}</span>
        </div>
        <img src={twitterIcon} alt={''} role={'presentation'} className={twitter} />
      </div>
      <p className={tweetText}>
        {data.text}
      </p>
      {data.text2 && <p className={tweetText}>
        {data.text2}
      </p>}
      {data?.hashtags &&
        <p className={hashtags}>
          {
            data?.hashtags?.map((tag) =>
              <React.Fragment key={tag}>#{tag}&nbsp;</React.Fragment>
            )
          }
        </p>
      }
    </div>
  )
}
export default Tweets