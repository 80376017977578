// extracted by mini-css-extract-plugin
export const wrapper = "BookPreview-module--wrapper--2KUrD";
export const title = "BookPreview-module--title--24aZS";
export const bookWrapper = "BookPreview-module--bookWrapper--2RfJV";
export const bookContainer = "BookPreview-module--bookContainer--3-pSK";
export const bookFlex = "BookPreview-module--bookFlex--3aXzb";
export const arrowActive = "BookPreview-module--arrowActive--2i__j";
export const arrowInactive = "BookPreview-module--arrowInactive--28uxI";
export const sliderWrapper = "BookPreview-module--sliderWrapper--I0Obz";
export const itemWrapper = "BookPreview-module--itemWrapper--85j0j";
export const itemImg = "BookPreview-module--itemImg--17hdF";
export const itemTitle = "BookPreview-module--itemTitle--9xp3p";
export const itemDescription = "BookPreview-module--itemDescription--1JGGB";