import * as React from "react"
import Header from '../components/Header/Header'
import TweetCarousel from "../components/TweetCarousel/TweetCarousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Features from "../components/Features/Features"
import BuyBook from "../components/BuyBook/BuyBook"
import BookPreview from "../components/BookPreview/BookPreview"
import MadeBy from "../components/MadeBy/MadeBy"
import JoinInsta from "../components/JoinInsta/JoinInsta"
import Newsletter from "../components/Newsletter/Newsletter"
import Books from "../components/Books/Books"
import Helmet from 'react-helmet'
import Universities from "../components/Universities/Universities"
import Tweets from "../components/Tweets/Tweets"
const IndexPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} >
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Light-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Regular-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Semibold-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content="The most comprehensive (over 769 pages!) UI design handbook ever created - Michal Malewicz" />
        <meta name="keywords" content="Design, UX, UI, Interface, user experience, book, ebook, publication, workshop, Designing ui, ux design" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hype4design" />
        <meta name="twitter:creator" content="DesigningUI" />
        <meta name="twitter:title" content="DesigningUI" />
        <meta name="twitter:description" content="The most comprehensive (over 769 pages!) UI design handbook ever created. Learn to design better apps and websites." />
        <meta name="twitter:image" content="http://designingui.com/hero_image.jpg" />
        <meta property="og:url" content="http://designingui.com/index.html" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Designing User Interfaces" />
        <meta property="og:image" content="http://designingui.com/hero_image.jpg" />
        <meta property="og:description" content="The most comprehensive (over 769 pages!) UI design handbook ever created. Learn to design better apps and websites." />
        <meta property="og:site_name" content="Designing User Interfaces" />
        <meta property="article:author" content="http://designingui.com" />
        <meta property="article:publisher" content="http://designingui.com" />
        <title>Designing User Interfaces 2024</title>
        <link rel="apple-touch-icon" sizes="57x57" href="/icons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icons/icons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/icons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/icons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
        <link rel="manifest" href="/icons/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/icons/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <script async defer data-domain="designingui.com" src="https://plausible.io/js/plausible.js"></script>
      </Helmet>
      <div className='allWrapper'>
        <Header />
        <TweetCarousel />
        <main className={'mainClass'}>
          <Features />
          <Universities />
          <BuyBook />
          <BookPreview />
          <MadeBy />
          <Tweets />
          <JoinInsta />
          <Newsletter />
          <Books />
        </main>
      </div>
    </>
  )
}

export default IndexPage
