import React from 'react'
import { wrapper, bookCover, bookCoverHD, rating, textContainer, bg, imageContainer, buttonContainer, description, title, discount, mobileButtonContainer, coverFeature } from './Header.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import imgHeart from './../../assets/images/heart.svg'
import Button from '../Button/Button'
import Link from '../Link/Link'

const Header = () => {
  return (
    <header className={wrapper}>
      <div className={textContainer}>
        <div className={rating}>
          <img src={imgHeart} alt={'serce'} />
          Loved by our readers worldwide:<span role={'img'} aria-label={'stars'}>⭐️⭐️⭐️⭐️⭐️</span><span style={{ wordBreak: 'keep-all' }}>(4.98)</span>
        </div>
        <h1 className={title}>
          The most comprehensive
          and biggest ebook about<br />
          <span>User</span>
          <span>Interface</span>
          <span>Design</span>
        </h1>
        <p className={description}>
          Over 769 pages of knowledge coming from 25+ years
          of experience from Product Design professionals.
          Learn all the essentials, helpful tricks, tips and industry
          know-how to <strong>become a UI Designer!</strong>
        </p>
        <div className={buttonContainer}>
          <Link modifier={'outline'} href={'#bookPreview'}>WHAT’S INSIDE?</Link>
          <Link modifier={'green'} arrow={true} href={'http://gum.co/uibook'} target="_blank" rel="noreferrer">I WANT THE BOOK!</Link>
          <div className={discount}>Limited offer -15%</div>
        </div>
      </div>
      <div className={imageContainer}>
        {/* <StaticImage src={"../../assets/images/book2x.png"} className={bookCover} alt={''} role={'presentation'} formats={["webp", "avif"]} width={369} height={593} placeholder={'blurred'} style={{ opacity: 0 }} /> */}
        <StaticImage src={"../../assets/images/BOOKHD.png"} className={bookCoverHD} alt={'book cover'} imgStyle={{ position: 'absolute' }} formats={["webp", "avif"]} width={984} height={1250} placeholder={'blurred'} />
        <div className={coverFeature}><span>100%</span>positive<br />reviews</div>
        <div className={coverFeature}><span>+769</span>pages</div>
      </div>
      <StaticImage src={"../../assets/images/top2x.jpg"} className={bg} alt={''} role={'presentation'} formats={["webp", "avif"]} width={2800} height={1168} placeholder={'blurred'} />
      <div className={mobileButtonContainer}>
        <Link modifier={'outline'} href={'#bookPreview'}>WHAT’S INSIDE?</Link>
        <Link modifier={'green'} arrow={true} href={'http://gum.co/uibook'} target="_blank" rel="noreferrer">I WANT THE BOOK!</Link>
      </div>
    </header>
  );
}

export default Header;