import React, { useState } from 'react'
import { wrapper, title, subtitle, container, control, control_indicator, checkboxContainer, infoWrapper, infoContainer, textInvisible } from './Newsletter.module.scss'
import Button from '../Button/Button'
import { Link } from 'gatsby'
const Newsletter = () => {

  const [textVis, setTextVis] = useState(false)
  return (
    <section className={wrapper}>
      <h2 className={title}>…and if you want the free sample <br /> and weekly design tips <br /><span>join</span> <span>our</span> <span>newsletter</span> ✉️</h2>
      <h3 className={subtitle}>We promise 100% cool stuff and 0% spam</h3>
      <form action='https://newsletter.hype4.com/subscribe' method='POST' acceptCharset='utf-8'>
        <div className={container}>
          <script src='https://www.google.com/recaptcha/api.js'></script>
          <label htmlFor={'name'}>Name</label>
          <input type='text' name='name' id='name' />
          <label htmlFor={'email'}>Email</label>
          <input type='email' name='email' id='email' />
          <div className={infoWrapper}>
            <div className={checkboxContainer}>
              <label className={control}>
                <input type='checkbox' name='gdpr' />
                <div className={control_indicator}></div>
              </label>
            </div>
            <div className={infoContainer}>
              <span><strong>Marketing permission</strong>: I give my consent to FrontendUnicorns to be in touch with me via email using the information I have provided in this form for the purpose of news, updates and marketing.</span>
              <span onClick={() => setTextVis(!textVis)} className={!textVis && textInvisible}><strong>What to expect</strong>: If you wish to withdraw your consent and stop hearing from us, simply click the unsubscribe link at the bottom of every email we send or contact us at projektowanieui@hype4.com. We value and respect your personal data and privacy. To view our privacy policy, please visit our website. By submitting this form, you agree that we may process your information in accordance with these terms.</span>
            </div>
          </div>
          <p className='g-recaptcha' data-sitekey='6LcFgh0bAAAAADj8s-cnbsYcl1ku5v11v5lN_hpw' style={{ margin: 0, transform: 'scale(0.9)' }}></p>
          <div style={{ display: 'none' }}>
            <input type='text' name='hp' id='hp' />
          </div>
          <input type='hidden' name='list' value='ulLNeTIXpYy2fbjZtDKoJg' />
          <input type='hidden' name='subform' value='yes' />
          <Button arrow={true} modifier={'newsletter'}>I WANT TO JOIN!</Button>
          <Link href={'/privacy-policy'}>Privacy policy</Link>
        </div>
      </form>

    </section >
  )
}

export default Newsletter
