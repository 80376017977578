import React from 'react'
import { wrapper, title, subtitle, oval, itemWrapper, itemContainer, pageImg1, itemImg, itemTitle, itemDescription, sliderWrapper, headerImgContainer, sliderClass } from './Features.module.scss'
//import BG from '../../assets/images/pixie/pixie-shadow.svg'
import Slider from 'react-slick'
import { StaticImage } from 'gatsby-plugin-image'
const Features = () => {

  const settings = {
    variableWidth: true,
    speed: 500,
    pauseOnHover: false,
    swipeToSlide: true,
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  }
  const featuresData = [
    {
      icon: '🤓',
      title: 'You\'ll gain the basic knowledge and industry tips to become a Product Designer.',
      description: 'Don\'t waste your time and money on expensive online courses or classes. This book is a condensed (and easy to understand) pill from industry experts that\'s bigger than most of what\'s out there. ',
      alt: 'unicorn emoji'
    },
    {
      icon: '📱',
      title: 'You\'ll learn how to design and build a readable, beautiful, and useful user interface.',
      description: 'Want to design a product, but you don\'t know where to start? We were there once too, and we know it can get overwhelming! That\'s why we cover all the essentials like resolutions, layouts, grids, typography, hierarchy, margins, styles, and a lot more! ',
      alt: 'graduation cap emoji'
    },
    {
      icon: '🔍',
      title: 'Notice the mistakes and bad practices in the products you use, & fix them instantly.',
      description: 'Designing an interface is one thing. But the ability to discern a good interface from a bad one is another thing that will be a breeze after reading this book. We\'ll show you examples of bad design and how to fix the most common problems in design today quickly. ',
      alt: 'crystal ball emoji'
    },
    {
      icon: '💡',
      title: 'Get tips and tricks to make your products a lot better!',
      description: 'Most popular problems are actually easy to fix. We\'ll tell you how to do it yourself or how to explain it to your team. You can UI Audit your own product like a boss!',
      alt: 'sunglasses emoji'
    },
    {
      icon: '🚀',
      title: 'Learn how to increase the conversion of your app or website.',
      description: 'We share many useful, tested tricks that will help your product deliver better results. Polish the essential elements of your product to guide your users to make the desired actions. More conversion can then lead to more sales!',
      alt: 'monocle emoji'
    },
    {
      icon: '🧠',
      title: 'Discover the design process and the ways professionals work.',
      description: 'We share our design processes with a focus on every single stage. We\'ll start from the basic rules of design and finish with developer handoff, design systems, and quality assurance. We guide you through that process with easy to follow steps.',
      alt: 'brain emoji'
    }
  ]

  return (
    <section className={wrapper}>
      <div className={oval} />
      <div className={headerImgContainer}>
        <StaticImage src={'../../assets/images/pages@2x.png'} formats={["webp", "avif"]} loading={'lazy'} placeholder={'blurred'} className={pageImg1} alt={''} role={'presentation'} width={850} height={297} />
      </div>

      <h2 className={title}>
        Designing User Interfaces is <br />
        <span>+769 pages</span> of professional knowledge, <br />
        tips, tricks and industry know-how <br />
      </h2>
      <h3 className={subtitle}>What will you learn?</h3>
      <div>
        <div className={sliderWrapper}>
          <Slider {...settings} className={sliderClass}>
            {featuresData.map((feature, index) =>
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <div src={feature.icon} className={itemImg} alt={feature.alt}>{feature.icon}</div>
                  <h3 className={itemTitle}>{feature.title}</h3>
                  <p className={itemDescription}>{feature.description}</p>
                </div>
              </div>
            )}
          </Slider>
        </div>
      </div>

    </section>
  )
}

export default Features