import React from 'react'
import "../../styles/variables.scss"
import { container, classArrow, outline, newsletter, purple } from './Button.module.scss'
import imgArrow from './../../assets/images/arrowClean.svg'

const Button = ({ children, onClick, modifier, arrow }) => {

  let myModifier
  switch (modifier) {
    case 'newsletter': myModifier = newsletter; break
    case 'outline': myModifier = outline; break
    case 'purple': myModifier = purple; break
    default: myModifier = null
  }
  return (
    <button onClick={onClick} className={`${container} ${myModifier}`}>
      {children}
      {arrow && <img src={imgArrow} className={classArrow} alt={''} role={'presentation'} />}
    </button>
  )
}

export default Button;