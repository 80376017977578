// extracted by mini-css-extract-plugin
export const wrapper = "Features-module--wrapper--1EMTP";
export const dotWrapper = "Features-module--dotWrapper--3H0gK";
export const books = "Features-module--books--3bzT4";
export const sliderClass = "Features-module--sliderClass--1uLnf";
export const oval = "Features-module--oval--_08TZ";
export const title = "Features-module--title--1TZf3";
export const subtitle = "Features-module--subtitle--3u7db";
export const itemWrapper = "Features-module--itemWrapper--2pkR0";
export const itemContainer = "Features-module--itemContainer--br1wM";
export const itemImg = "Features-module--itemImg--1nR2j";
export const itemTitle = "Features-module--itemTitle--3Eww1";
export const headerImgContainer = "Features-module--headerImgContainer--1_ci0";
export const pageImg1 = "Features-module--pageImg1--k71Oa";
export const itemDescription = "Features-module--itemDescription--Oi7ey";
export const sliderWrapper = "Features-module--sliderWrapper--1oBi9";
export const dotImg11 = "Features-module--dotImg11--PIwZO";
export const dotImg12 = "Features-module--dotImg12--S06Tj";
export const dotImg13 = "Features-module--dotImg13--gXpWj";
export const dotImg14 = "Features-module--dotImg14--1LMXD";
export const dotImg21 = "Features-module--dotImg21--1fUv3";
export const dotImg22 = "Features-module--dotImg22--2vJvZ";
export const dotImg23 = "Features-module--dotImg23--3AAYw";
export const dotImg31 = "Features-module--dotImg31--1B0dK";
export const dotImg32 = "Features-module--dotImg32--KWq_3";
export const dotImg33 = "Features-module--dotImg33--2pDah";
export const dotImg34 = "Features-module--dotImg34--oomeK";
export const dotImg35 = "Features-module--dotImg35--2d-Lh";