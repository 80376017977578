import React from 'react'
import { wrapper, title, heartIcon, person, personImage, personName, personYoutube, personLink, container, personLinkContainer, peopleContainer, personMedium, personTwitter, personLinkedIn, featureText, featureIcon, feature, featureContainer, oval1, oval2 } from './MadeBy.module.scss'
import heart from '../../assets/images/heart.svg'
import mediumImg from '../../assets/images/medium.svg'
import linkedInImg from '../../assets/images/linkedinIcon.svg'
import twitterImg from '../../assets/images/twitterIcon.svg'
import instaImg from '../../assets/images/insta.svg'
import youtubeImg from '../../assets/images/youtubeIcon.svg'
import { StaticImage } from 'gatsby-plugin-image'
const MadeBy = () => {
  return (
    <section className={wrapper}>
      <div className={oval1} />
      <div className={oval2} />
      <h2 className={title}>
        Made by designers <img src={heart} className={heartIcon} alt={'heart emoji'} /><br />
        for designers (and everyone else!)
      </h2>
      <div className={container}>
        <div className={peopleContainer}>
          <div className={person}>
            <StaticImage src={'../../assets/images/michal@2x.png'} className={personImage} alt={'person profile'} width={58} height={58} formats={["webp", "avif"]} />
            <div>
              <span className={personName}>Mike Malewicz</span>
              <div className={personLinkContainer}>
                <a href={'https://michalmalewicz.medium.com/'} target="_blank" rel="noreferrer" className={personLink}><img src={mediumImg} className={personMedium} alt={'Mike Malewicz medium profile'} /></a>
                <a href={'https://www.youtube.com/c/MalewiczHype'} target="_blank" rel="noreferrer" className={personLink}><img src={youtubeImg} className={personYoutube} alt={'Mike Malewicz twitter profile'} /></a>
                <a href={'https://twitter.com/michalmalewicz'} target="_blank" rel="noreferrer" className={personLink}><img src={twitterImg} className={personTwitter} alt={'Mike Malewicz youtube profile'} /></a>
                <a href={'https://www.linkedin.com/in/michalmalewicz'} target="_blank" rel="noreferrer" className={personLink}><img src={linkedInImg} className={personLinkedIn} alt={'Mike Malewicz linkedin profile'} /></a>
              </div>
            </div>
          </div>
          <div className={person}>
            <StaticImage src={'../../assets/images/diana@2x.png'} className={personImage} alt={'person profile'} width={58} height={58} formats={["webp", "avif"]} />
            <div >
              <span className={personName}>Diana Malewicz</span>
              <div className={personLinkContainer}>
                <a href={'https://medium.com/@dianamalewicz'} target="_blank" rel="noreferrer" className={personLink}><img src={mediumImg} className={personMedium} alt={'Diana Malewicz medium profile'} /></a>
                <a href={'https://www.instagram.com/dianecatem/'} target="_blank" rel="noreferrer" className={personLink}><img src={instaImg} className={personTwitter} alt={'Diana Malewicz instagram profile'} /></a>
                <a href={'https://twitter.com/DianaMalewicz'} target="_blank" rel="noreferrer" className={personLink}><img src={twitterImg} className={personTwitter} alt={'Diana Malewicz medium profile'} /></a>
                <a href={'https://www.linkedin.com/in/dianamalewicz'} target="_blank" rel="noreferrer" className={personLink}><img src={linkedInImg} className={personLinkedIn} alt={'Diana Malewicz medium profile'} /></a>
              </div>
            </div>
          </div>
        </div>
        <div className={featureContainer}>
          <div className={feature}>
            <span className={featureIcon} role={'img'} aria-label={'technologist'}>🧑🏻‍💻</span>
            <div className={featureText}>We’re  professional Product Designers with 25+ years of industry experience combined.</div>
          </div>
          <div className={feature}>
            <span className={featureIcon} role={'img'} aria-label={'briefcase'}>💼</span>
            <div className={featureText}>We have an agency background, and decided to run our own - HYPE4 - succesfully for 8 years straight and counting!</div>
          </div>
          <div className={feature}>
            <span className={featureIcon} role={'img'} aria-label={'trophy'}>🏆</span>
            <div className={featureText}>We did projects for both big, established companies and small startups. Currently we have around 500 finished projects under our belts.</div>
          </div>
          <div className={feature}>
            <span className={featureIcon} role={'img'} aria-label={'graduation cap'}>🎓</span>
            <div className={featureText}>We talk at various Design Conferences, and Michał teaches UI Design at a university in Poland.</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MadeBy
