// extracted by mini-css-extract-plugin
export const wrapper = "Newsletter-module--wrapper--2i4Qy";
export const title = "Newsletter-module--title--28PdU";
export const subtitle = "Newsletter-module--subtitle--_tsxW";
export const textInvisible = "Newsletter-module--textInvisible--1Dqd-";
export const container = "Newsletter-module--container--3BC7U";
export const infoWrapper = "Newsletter-module--infoWrapper--11Bi4";
export const infoContainer = "Newsletter-module--infoContainer--8Hkgo";
export const checkboxContainer = "Newsletter-module--checkboxContainer--2a5UK";
export const control = "Newsletter-module--control--3fu2J";
export const control_indicator = "Newsletter-module--control_indicator--1uewL";
export const sRipple = "Newsletter-module--s-ripple--2e3nY";
export const sRippleDup = "Newsletter-module--s-ripple-dup--1Dr2T";