// extracted by mini-css-extract-plugin
export const wrapper = "Tweets-module--wrapper--m59vi";
export const tweetWrapper = "Tweets-module--tweetWrapper--2uyXF";
export const tweetColumn = "Tweets-module--tweetColumn--2NlUV";
export const tweetContainer = "Tweets-module--tweetContainer--34Vcn";
export const twitter = "Tweets-module--twitter--3q9Rb";
export const tweetUpper = "Tweets-module--tweetUpper--1ylsR";
export const nameContainer = "Tweets-module--nameContainer--e6dEt";
export const tweetText = "Tweets-module--tweetText--3cSpK";
export const imgSrc = "Tweets-module--imgSrc--2oEHw";
export const hashtags = "Tweets-module--hashtags--1Dz7h";
export const title = "Tweets-module--title--3AqFa";
export const itemContainer = "Tweets-module--itemContainer--2lQfl";