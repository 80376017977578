// extracted by mini-css-extract-plugin
export const wrapper = "BuyBook-module--wrapper--UId86";
export const title = "BuyBook-module--title--2mCWH";
export const description = "BuyBook-module--description--ZWSLO";
export const flexContainer = "BuyBook-module--flexContainer--hH7v3";
export const summaryWrapper = "BuyBook-module--summaryWrapper--3NdI6";
export const upper = "BuyBook-module--upper--2emcZ";
export const upperIcon = "BuyBook-module--upperIcon--29e9g";
export const textWrapper = "BuyBook-module--textWrapper--1w0ts";
export const textContainer = "BuyBook-module--textContainer--3STW1";
export const bonus = "BuyBook-module--bonus--2k8vk";
export const pointWrapper = "BuyBook-module--pointWrapper--fcgWj";
export const pointColumn = "BuyBook-module--pointColumn--1esZy";
export const pointContainer = "BuyBook-module--pointContainer--s3aF_";
export const point = "BuyBook-module--point--Duziw";
export const bottom = "BuyBook-module--bottom--1I-bF";
export const regPriceWrapper = "BuyBook-module--regPriceWrapper--19yC6";
export const regPriceText = "BuyBook-module--regPriceText--2FnrL";
export const regPriceNumber = "BuyBook-module--regPriceNumber--1k2fL";
export const specPriceWrapper = "BuyBook-module--specPriceWrapper--h7VtH";
export const specPriceNumber = "BuyBook-module--specPriceNumber--3quCk";
export const specPriceText = "BuyBook-module--specPriceText--fa9Zf";
export const specPriceEmoji = "BuyBook-module--specPriceEmoji--3kB6R";
export const separator = "BuyBook-module--separator--12bnA";
export const saveWrapper = "BuyBook-module--saveWrapper--2Rv3i";
export const imageWrapper = "BuyBook-module--imageWrapper--34ENK";
export const coverFeature = "BuyBook-module--coverFeature--3eO01";
export const bookImage = "BuyBook-module--bookImage--3ngg0";