import React from 'react'
import { wrapper, title, container, image } from './JoinInsta.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
const JoinInsta = () => {
  return (
    <section className={wrapper}>
      <h2 className={title}>Join our instagram community<br />for free, amazing content!<br /><a href={'https://www.instagram.com/hype4academy'}>@hype4academy</a></h2>
      <div className={container}>

        <StaticImage src={'../../assets/images/in1@2x.png'} className={image} alt={'Create your own bubble glass icon'} formats={["webp", "avif"]} width={426} height={426} />
        <StaticImage src={'../../assets/images/in2@2x.png'} className={image} alt={'Why should you try to become a unicorn'} formats={["webp", "avif"]} width={426} height={426} />
        <StaticImage src={'../../assets/images/in3@2x.png'} className={image} alt={'The hottest sans serif fonts for 2021'} formats={["webp", "avif"]} width={426} height={426} />
        <StaticImage src={'../../assets/images/in4@2x.png'} className={image} alt={'How to create a converting CTA button'} formats={["webp", "avif"]} width={426} height={426} />
        <StaticImage src={'../../assets/images/in5@2x.png'} className={image} alt={'Make your own clay phone mockup the easy way'} formats={["webp", "avif"]} width={426} height={426} />

      </div>
    </section>
  )
}

export default JoinInsta