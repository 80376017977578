import React from 'react'
import { wrapper, title, description, flexContainer, bookImage, summaryWrapper, upper, textWrapper, textContainer, upperIcon, pointColumn, pointWrapper, pointContainer, point, bonus, bottom, regPriceWrapper, regPriceText, regPriceNumber, specPriceWrapper, specPriceEmoji, specPriceText, specPriceNumber, separator, saveWrapper, imageWrapper, coverFeature } from './BuyBook.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../Link/Link'
const BuyBook = () => {
  return (
    <section className={wrapper}>
      <h2 className={title}>Buy the eBook<br />“Designing User Interfaces”</h2>
      <div className={description}><span role={'img'} aria-label={'stars'}>⭐️⭐️⭐️⭐️⭐️</span>(5.00)</div>
      <h3 className={description}>What exactly will you get?</h3>
      <div className={flexContainer}>
        <div className={imageWrapper}>
          <StaticImage formats={["webp", "avif"]} width={336} height={540} src={'../../assets/images/book2.png'} className={bookImage} alt={'book cover'} />
          <div className={coverFeature}><span>100%</span>positive<br />reviews</div>
          <div className={coverFeature}><span>+769</span>pages</div>
        </div>
        <div className={summaryWrapper}>
          <div className={upper}>
            <div className={textWrapper}>
              <span className={upperIcon} role={'img'} aria-label={'book'}>📒</span>
              <p className={textContainer}>
                A colorful <strong>+769 pages book in PDFs</strong> with complete, essential knowledge, industry know-how, tips, tricks and experiences we had during our
                25+ years of Interface Design that will help you become a UI Designer.
              </p>
            </div>
            <span className={bonus}>Bonus materials:</span>
            <div className={textWrapper}>
              <span className={upperIcon} role={'img'} aria-label={'gift'}>🎁</span>
              <p className={textContainer}>
                Files compatible with Sketch, Figma and XD, with an icon pack (both outline and solid ones) for your first projects!
              </p>
            </div>

            <div className={textWrapper}>
              <span className={upperIcon} role={'img'} aria-label={'gift'}>🎁</span>
              <p className={textContainer}>
                Extra chapters that will help in kickstarting your career: <br />
                <div className={pointWrapper}>
                  <div className={pointColumn}>
                    <div className={pointContainer}>
                      <div className={point} /><span>Design process</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>Design Systems</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>UI Design Styles</span>
                    </div>
                  </div>
                  <div className={pointColumn}>
                    <div className={pointContainer}>
                      <div className={point} /><span>Developer Handoff</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>Prototyping</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>UI Auditing</span>
                    </div>
                  </div>
                  <div className={pointColumn}>
                    <div className={pointContainer}>
                      <div className={point} /><span>Show your work</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>How to start a design</span>
                    </div>
                    <div className={pointContainer}>
                      <div className={point} /><span>Success in design</span>
                    </div>
                  </div>
                </div>
              </p>
            </div>

            <div className={textWrapper}>
              <span className={upperIcon} role={'img'} aria-label={'gift'}>🎁</span>
              <p className={textContainer}>
                Regular updates of the ebook with new chapters and even more knowledge over the course of  1 year.
              </p>
            </div>
          </div>
          <div className={bottom}>
            <div className={regPriceWrapper}>
              <span className={regPriceText}>Regular price:</span>
              <span className={regPriceNumber}>$50</span>
            </div>
            <div className={specPriceWrapper}>
              <span className={specPriceEmoji} role={'img'} aria-label={'Partying Face'}>🥳</span>
              <span className={specPriceText}>Special price offer:</span>
              <div className={separator} />
              <span className={specPriceNumber}>$29</span>
            </div>
            <div className={saveWrapper}>
              <p>By purchasing right now<br /><span>you save 25%!</span></p>
              <Link arrow={true} modifier={'green'} href={'http://gum.co/uibook'} target="_blank" rel="noreferrer">I'M BUYING THE BOOK</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyBook;