import React from 'react'
import { container, classArrow, green, outline, purple } from './Link.module.scss'
import imgArrow from './../../assets/images/arrowClean.svg'

const Link = ({ children, href, modifier, arrow, target, rel }) => {
  let myModifier
  switch (modifier) {
    case 'outline': myModifier = outline; break
    case 'green': myModifier = green; break
    case 'purple': myModifier = purple; break
    default: myModifier = null
  }
  return (
    <a href={href} className={`${container} ${myModifier}`} target={target} rel={rel}>
      {children}
      {arrow && <img src={imgArrow} className={classArrow} alt={''} role={'presentation'} />}
    </a>
  )
}

export default Link