import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { wrapper, title, container } from './Universities.module.scss'

const Universities = () => {
  return (
    <div className={wrapper}>
      <h2 className={title}>Used by the staff and faculty on</h2>
      <div className={container}>
        <StaticImage src={'../../assets/images/uni/logo1@2x.png'} alt={'university'} formats={["webp", "avif"]} width={159} height={89} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo2@2x.png'} alt={'university'} formats={["webp", "avif"]} width={222} height={86} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo3@2x.png'} alt={'university'} formats={["webp", "avif"]} width={139} height={88} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo4@2x.png'} alt={'university'} formats={["webp", "avif"]} width={196} height={68} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo5@2x.png'} alt={'university'} formats={["webp", "avif"]} width={244} height={91} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo6@2x.png'} alt={'university'} formats={["webp", "avif"]} width={154} height={85} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo7@2x.png'} alt={'university'} formats={["webp", "avif"]} width={222} height={78} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo8@2x.png'} alt={'university'} formats={["webp", "avif"]} width={256} height={71} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo9@2x.png'} alt={'university'} formats={["webp", "avif"]} width={173} height={67} placeholder={'blurred'} />
        <StaticImage src={'../../assets/images/uni/logo10@2x.png'} alt={'university'} formats={["webp", "avif"]} width={215} height={88} placeholder={'blurred'} />
      </div>
    </div>
  )
}

export default Universities