import React, { useRef, useState } from 'react'
import { wrapper, title, bookContainer, bookWrapper, bookFlex, arrowActive, arrowInactive, itemImg, sliderWrapper, itemWrapper } from './BookPreview.module.scss'
import HTMLFlipBook from 'react-pageflip'
import page1 from '../../assets/images/pages/p1.png'
import page2 from '../../assets/images/pages/p2.png'
import page3 from '../../assets/images/pages/p3.png'
import page4 from '../../assets/images/pages/p4.png'
import page5 from '../../assets/images/pages/p5.png'
import page6 from '../../assets/images/pages/p6.png'
import page7 from '../../assets/images/pages/p7.png'
import page8 from '../../assets/images/pages/p8.png'
import arrowRight from '../../assets/images/arrowRight.png'
import arrowLeft from '../../assets/images/arrowLeft.png'
import Slider from 'react-slick'
const BookPreview = () => {
  const bookRef = useRef()
  const [currentPage, setCurrentPage] = useState(0)
  const bookLength = 8
  const settings = {
    variableWidth: true,
    speed: 500,
    pauseOnHover: false,
    swipeToSlide: false,
    focusOnSelect: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: false
  }
  const pagesData = [{
    image: page1,
    alt: 'unicorn emoji'
  },
  {
    image: page2,
    alt: 'graduation cap emoji'
  },
  {
    image: page3,
    alt: 'crystal ball emoji'
  },
  {
    image: page4,
    alt: 'sunglasses emoji'
  },
  {
    image: page5,
    alt: 'monocle emoji'
  },
  {
    image: page6,
    alt: 'brain emoji'
  },
  {
    image: page7,
    alt: 'beer emoji'
  },
  {
    image: page8,
    alt: 'tools emoji'
  }]
  return (
    <section className={wrapper} id={'bookPreview'}>
      <h2 className={title}>Look inside! <span role={'img'} aria-label={'eyes'}>👀</span></h2>
      <div className={bookFlex}>
        <img src={arrowLeft} alt={'previous page'} className={currentPage > 0 ? arrowActive : arrowInactive} onClick={() => bookRef.current.getPageFlip().flipPrev()} />
        <div className={bookWrapper}>
          <div className={bookContainer}>
            <HTMLFlipBook width={385} height={545} drawShadow={true} flippingTime={200} ref={bookRef} useMouseEvents={true} size={'stretch'} onFlip={(number) => setCurrentPage(number.data)}>
              <img src={page1} loading={'lazy'} alt={'page 1'} width={385} height={545} />
              <img src={page2} loading={'lazy'} alt={'page 2'} width={385} height={545} />
              <img src={page3} loading={'lazy'} alt={'page 3'} width={385} height={545} />
              <img src={page4} loading={'lazy'} alt={'page 4'} width={385} height={545} />
              <img src={page5} loading={'lazy'} alt={'page 5'} width={385} height={545} />
              <img src={page6} loading={'lazy'} alt={'page 6'} width={385} height={545} />
              <img src={page7} loading={'lazy'} alt={'page 7'} width={385} height={545} />
              <img src={page8} loading={'lazy'} alt={'page 8'} width={385} height={545} />
            </HTMLFlipBook>
          </div>
        </div>
        <img src={arrowRight} alt={'next page'} className={currentPage + 2 < bookLength ? arrowActive : arrowInactive} onClick={() => bookRef.current.getPageFlip().flipNext()} />
      </div>
      <div className={sliderWrapper}>
        <Slider {...settings}>
          {pagesData.map((feature, index) =>
            <div className={itemWrapper} key={index}>
              <img src={feature.image} loading={'lazy'} className={itemImg} alt={feature.alt} width={385} height={545} />
            </div>
          )}
        </Slider>
      </div>

    </section>
  )
}

export default BookPreview
