import React from 'react'
import imgPerson1 from '../../assets/images/ppl/Anette71914469.jpg'
import imgPerson2 from '../../assets/images/ppl/dombrowskikamil.png'
import imgPerson3 from '../../assets/images/ppl/dombrowskikamil.png'
import imgPerson4 from '../../assets/images/ppl/dombrowskikamil.png'
import imgPerson5 from '../../assets/images/ppl/dombrowskikamil.png'
import imgPerson6 from '../../assets/images/ppl/Matroyka.jpg'
import imgPerson7 from '../../assets/images/ppl/Klaudia09353900.jpg'
import imgPerson8 from '../../assets/images/ppl/julia_sanczyk.jpg'
import imgPerson9 from '../../assets/images/ppl/natusiek84.jpeg'
import imgPerson10 from '../../assets/images/ppl/saywee.jpg'
import imgPerson11 from '../../assets/images/ppl/inspiredmac.jpg'
import imgPerson12 from '../../assets/images/ppl/johnpassfield.jpeg'
import imgPerson13 from '../../assets/images/ppl/markgoho.png'
import imgPerson14 from '../../assets/images/ppl/krolowalisow.jpg'
import imgPerson15 from '../../assets/images/ppl/dombrowskikamil.png'
import imgPerson16 from '../../assets/images/ppl/danhoelck.jpg'
import imgPerson17 from '../../assets/images/ppl/dhartipebhoj.jpg'
import imgPerson18 from '../../assets/images/ppl/daniielAMD.jpg'
import { container, itemWrapper, itemContainer, tweetImg, wrapper, slider, slideTrack, sliderSecond } from './TweetCarousel.module.scss'
const TweetCarousel = () => {

  const tweetData = [
    {
      img: imgPerson1,
      text: 'Great book for all UI designers out there',
      author: 'Anette71914469'
    },
    {
      img: imgPerson2,
      text: 'My digital bookshelf is now complete!',
      author: 'tirrapy'
    },
    {
      img: imgPerson3,
      text: 'Mindblowing and beautiful at the same time!',
      author: 'm_stefanska'
    },
    {
      img: imgPerson4,
      text: 'A must-have for everyone who makes digital products',
      author: 'szymeek_'
    },
    {
      img: imgPerson5,
      text: 'Lots of great knowledge with easy to grasp delivery.',
      author: 'mateuszharas'
    },
    {
      img: imgPerson6,
      text: 'I\'m already using these tips in my designs!',
      author: 'Matroyka'
    },
    {
      img: imgPerson7,
      text: 'A great dose of knowledge for all designers!',
      author: 'Klaudia09353900'
    },
    {
      img: imgPerson8,
      text: 'I\'m learning to design better—step by step.',
      author: 'julia_sanczyk'
    },
    {
      img: imgPerson9,
      text: 'That kind of treasure is a new thing in UI design.',
      author: 'natusiek84'
    }
  ]

  const tweetData2 = [
    {
      img: imgPerson10,
      text: 'Kickstarter for newbies, refresher for veterans.',
      author: 'saywee'
    },
    {
      img: imgPerson11,
      text: 'This is the 360 view of UI that I’ve been waiting for.',
      author: 'inspiredmac'
    },
    {
      img: imgPerson12,
      text: 'Great UI tips that I’ve already applied to my latest mobile apps',
      author: 'johnpassfield'
    },
    {
      img: imgPerson13,
      text: 'I read the first few chapters and it looks amazing!!',
      author: 'markgoho'
    },
    {
      img: imgPerson14,
      text: 'This book is seriously IT.',
      author: 'krolowalisow'
    },
    {
      img: imgPerson15,
      text: 'A great way to learn the basics of UI and more.',
      author: 'dombrowskikamil'
    },
    {
      img: imgPerson16,
      text: 'Excellent info for new and veteran UI designers alike',
      author: 'danhoelck'
    },
    {
      img: imgPerson17,
      text: 'A lot of real world practical stuff in there!',
      author: 'dhartipebhoj'
    },
    {
      img: imgPerson18,
      text: 'Hey design world, you need to check this out.',
      author: 'daniielAMD'
    }
  ]

  return (
    <section className={wrapper}>
      <div className={container}>
        <div className={slider}>
          <div className={slideTrack}>
            {tweetData.concat(tweetData).map((tweet, index) =>
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <img src={tweet.img} width={40} height={40} className={tweetImg} alt={`${tweet.author} twitter profile`} />
                  {tweet.text} - @{tweet.author}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={sliderSecond}>
          <div className={slideTrack}>
            {tweetData2.concat(tweetData2).reverse().map((tweet, index) =>
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <img src={tweet.img} width={40} height={40} className={tweetImg} alt={`${tweet.author} twitter profile`} />
                  {tweet.text} - @{tweet.author}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}


export default TweetCarousel;