// extracted by mini-css-extract-plugin
export const wrapper = "TweetCarousel-module--wrapper--2cp1s";
export const container = "TweetCarousel-module--container--qVodW";
export const itemWrapper = "TweetCarousel-module--itemWrapper--hXlP5";
export const itemContainer = "TweetCarousel-module--itemContainer--1mtOu";
export const secondSlider = "TweetCarousel-module--secondSlider--2Wskj";
export const firstSlider = "TweetCarousel-module--firstSlider--38eR0";
export const tweetImg = "TweetCarousel-module--tweetImg--FkkYK";
export const slider = "TweetCarousel-module--slider--aaZIk";
export const sliderSecond = "TweetCarousel-module--sliderSecond--2pzUg";
export const slideTrack = "TweetCarousel-module--slideTrack--yXprh";
export const scroll = "TweetCarousel-module--scroll--2ZRof";
export const slide = "TweetCarousel-module--slide--1oRHn";